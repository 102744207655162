var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%", "min-height": "350px" } },
    [
      _c("filter-container", {
        ref: "filterContainer",
        attrs: {
          arg: _vm.arg,
          placeholder: _vm.placeholder,
          isShowObject: _vm.isShowObject,
          multiple: _vm.multiple,
          loading: _vm.loadingDownloadFileByDkAndNo,
          dictionary: _vm.dictionary,
          importAction: "'postGoodsImport'"
        },
        on: {
          handleSearchList: function($event) {
            return _vm.handleSearchListF(1)
          },
          handleCommand: _vm.handleCommand,
          countryClick: _vm.countryClick,
          putClick: _vm.putClick,
          offClick: _vm.offClick,
          searchClick: _vm.searchClick,
          importClick: _vm.importClick,
          classClick: _vm.classClick,
          SKUClick: _vm.SKUClick,
          subBarCodeClick: _vm.subBarCodeClick,
          EBPClick: _vm.EBPClick,
          packageQtyClick: _vm.packageQtyClick,
          outStockClick: _vm.outStockClick,
          downloadTemplateGoods: _vm.downloadTemplateGoods,
          markClick: _vm.markClick,
          baseline: _vm.baseline,
          recordClick: _vm.recordClick,
          downloadFileByDkAndNo: _vm.downloadFileByDkAndNo,
          handleChangeCategory: _vm.handleChangeCategory,
          handleResetSearch: _vm.handleResetSearch,
          handleExport: _vm.handleExport,
          handleShowColumn: _vm.handleShowColumn
        }
      }),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "tableData",
          staticClass: "table-fixed",
          staticStyle: { width: "100%" },
          attrs: {
            id: "goodsTable",
            data: _vm.pageData.list,
            "tree-props": {
              children: "packageQtyList",
              hasChildren: "hasChildren"
            },
            "default-expand-all": "",
            height: "calc(100vh - 430px)",
            border: "",
            "row-key": "goodsId",
            "header-cell-style": { background: "#EAEEF9", color: "#32353a" }
          },
          on: {
            select: function(selection) {
              return _vm.select(
                selection,
                this$1.$refs.tableData,
                this$1.pageData.list
              )
            },
            "select-all": function(selection) {
              return _vm.selectAll(
                selection,
                this$1.$refs.tableData,
                this$1.pageData.list
              )
            },
            "selection-change": _vm.handleSelectionChange
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "selection",
              "reserve-selection": true,
              align: "center",
              width: "55"
            }
          }),
          _c("el-table-column", { attrs: { width: "50px", align: "center" } }),
          _vm.tableColumSetting.includes("ProductID")
            ? _c("el-table-column", {
                attrs: {
                  prop: "goodsId",
                  label: _vm.$t("common.ProductID"),
                  align: "center",
                  width: "150",
                  "show-overflow-tooltip": ""
                }
              })
            : _vm._e(),
          _vm.tableColumSetting.includes("SKU_EAN_Encoding")
            ? _c("el-table-column", {
                attrs: {
                  prop: "goodsCode",
                  label: _vm.$t("common.SKU_EAN_Encoding"),
                  width: "150",
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v("SKU:" + _vm._s(scope.row.sku))]),
                          _c("br"),
                          _c("span", [
                            _vm._v("EAN:" + _vm._s(scope.row.barCode))
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v("EAN-SUB:" + _vm._s(scope.row.subBarCode))
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("common.coding")) +
                                ":" +
                                _vm._s(scope.row.goodsCode)
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2158704185
                )
              })
            : _vm._e(),
          _vm.tableColumSetting.includes("Productimage1")
            ? _c("el-table-column", {
                attrs: {
                  prop: "imageUrl",
                  label: _vm.$t("common.Productimage1"),
                  width: "150",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.imgType == "img"
                            ? _c("listImage", {
                                attrs: { imgUrl: scope.row.imageUrl }
                              })
                            : scope.row.imageUrl != "" &&
                              scope.row.imgType == "video"
                            ? _c("video", {
                                staticStyle: { height: "100px", width: "auto" },
                                attrs: {
                                  src: _vm.ossHost + scope.row.imageUrl,
                                  controls: "controls"
                                }
                              })
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  527258353
                )
              })
            : _vm._e(),
          _vm.tableColumSetting.includes("Category")
            ? _c("el-table-column", {
                staticStyle: {
                  width: "100px",
                  overflow: "hidden",
                  "white-space": "nowrap",
                  "text-overflow": "ellipsis",
                  "-o-text-overflow": "ellipsis"
                },
                attrs: {
                  prop: "treeList",
                  label: _vm.$t("common.Category"),
                  align: "center",
                  height: "55",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return _vm._l(scope.row.treeList, function(
                          item,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _vm._l(item.parents, function(item2, index2) {
                                return _c("span", { key: index2 }, [
                                  _c("span", [
                                    _vm._v(_vm._s(item2.title) + ">")
                                  ])
                                ])
                              }),
                              _c("span", [_vm._v(_vm._s(item.title))])
                            ],
                            2
                          )
                        })
                      }
                    }
                  ],
                  null,
                  false,
                  407225598
                )
              })
            : _vm._e(),
          _vm.tableColumSetting.includes("ProductName")
            ? _c("el-table-column", {
                attrs: {
                  prop: "title",
                  label: _vm.$t("common.ProductName"),
                  align: "center",
                  width: "170",
                  "show-overflow-tooltip": ""
                }
              })
            : _vm._e(),
          _vm.tableColumSetting.includes("Countrieslisted")
            ? _c("el-table-column", {
                attrs: {
                  prop: "goodsSpecials",
                  label: _vm.$t("common.Countrieslisted"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return _vm._l(scope.row.goodsSpecials, function(
                          s,
                          index
                        ) {
                          return _c("span", { key: index }, [
                            s.shelf
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$dict.getDictValue(
                                        "countrys",
                                        s.countryCode
                                      )
                                    )
                                  ),
                                  _c("br")
                                ])
                              : _vm._e()
                          ])
                        })
                      }
                    }
                  ],
                  null,
                  false,
                  2013868671
                )
              })
            : _vm._e(),
          _vm.tableColumSetting.includes("Salesmultiples")
            ? _c("el-table-column", {
                staticStyle: {
                  width: "100px",
                  overflow: "hidden",
                  "white-space": "nowrap",
                  "text-overflow": "ellipsis",
                  "-o-text-overflow": "ellipsis"
                },
                attrs: {
                  prop: "goodsSpecials",
                  label: _vm.$t("common.Salesmultiples"),
                  align: "center",
                  height: "55",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return _vm._l(scope.row.goodsSpecials, function(
                          item,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              item.shelf
                                ? [
                                    _c("span", [
                                      _vm._v(_vm._s(item.countryCode) + ": ")
                                    ]),
                                    _c("span", [
                                      _vm._v(_vm._s(item.saleDoubleEbpNew))
                                    ])
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        })
                      }
                    }
                  ],
                  null,
                  false,
                  172267755
                )
              })
            : _vm._e(),
          _vm.tableColumSetting.includes("PackingQuantity")
            ? _c("el-table-column", {
                attrs: {
                  prop: "packageQuantity",
                  label: _vm.$t("common.PackingQuantity"),
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              })
            : _vm._e(),
          _vm.tableColumSetting.includes("Fixedprice1")
            ? _c("el-table-column", {
                attrs: {
                  prop: "prices",
                  label: _vm.$t("common.Fixedprice1"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return _vm._l(scope.row.prices, function(s, index) {
                          return _c("span", { key: index }, [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: s.fixedPrice && s.fixedPrice >= 0,
                                    expression:
                                      "s.fixedPrice && s.fixedPrice >= 0"
                                  }
                                ]
                              },
                              [
                                _vm._v(
                                  _vm._s(s.countryCode) +
                                    ":" +
                                    _vm._s(Number(s.fixedPrice).toFixed(2)) +
                                    _vm._s(
                                      _vm.$dict.getDictCurrencyValue(
                                        s.countryCode
                                      )
                                    )
                                )
                              ]
                            ),
                            _c("br")
                          ])
                        })
                      }
                    }
                  ],
                  null,
                  false,
                  3879140839
                )
              })
            : _vm._e(),
          !_vm.scene.isNonSelf && _vm.tableColumSetting.includes("Lowestprice")
            ? _c("el-table-column", {
                attrs: {
                  prop: "prices",
                  label: _vm.$t("common.Lowestprice"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return _vm._l(scope.row.prices, function(s, index) {
                          return _c("span", { key: index }, [
                            s.lowestPrice
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(s.countryCode) +
                                      ":" +
                                      _vm._s(Number(s.lowestPrice).toFixed(2)) +
                                      _vm._s(
                                        _vm.$dict.getDictCurrencyValue(
                                          s.countryCode
                                        )
                                      )
                                  ),
                                  _c("br")
                                ])
                              : _vm._e()
                          ])
                        })
                      }
                    }
                  ],
                  null,
                  false,
                  3335674497
                )
              })
            : _vm._e(),
          !_vm.scene.isNonSelf && _vm.tableColumSetting.includes("fixedprice")
            ? _c("el-table-column", {
                attrs: {
                  prop: "prices",
                  label: _vm.$t("common.fixedprice"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return _vm._l(scope.row.prices, function(s, index) {
                          return _c("span", { key: index }, [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: s.resultPrice && s.resultPrice >= 0,
                                    expression:
                                      "s.resultPrice && s.resultPrice >= 0"
                                  }
                                ]
                              },
                              [
                                _vm._v(
                                  _vm._s(s.countryCode) +
                                    ":" +
                                    _vm._s(Number(s.resultPrice).toFixed(2)) +
                                    _vm._s(
                                      _vm.$dict.getDictCurrencyValue(
                                        s.countryCode
                                      )
                                    )
                                )
                              ]
                            ),
                            _c("br")
                          ])
                        })
                      }
                    }
                  ],
                  null,
                  false,
                  4021014072
                )
              })
            : _vm._e(),
          !_vm.scene.isNonSelf &&
          _vm.tableColumSetting.includes("Promotionprice_unitprice")
            ? _c("el-table-column", {
                attrs: {
                  prop: "prices",
                  label: _vm.$t("common.Promotionprice_unitprice"),
                  width: "120",
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm.isShowGoodsPromotion(
                            scope.row.promotionRuleCountrys,
                            "NEW"
                          )
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-weight": "bold",
                                        "text-align": "left",
                                        display: "block"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("common.NewUserPromotion")
                                        )
                                      ),
                                      _c("br")
                                    ]
                                  ),
                                  _vm._l(
                                    scope.row.promotionRuleCountrys,
                                    function(promotion, index) {
                                      return promotion.userType == "NEW"
                                        ? _c("span", { key: index }, [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm.$dict.getDictValue(
                                                    "countrys",
                                                    promotion.countryCode
                                                  )
                                                ) +
                                                ":" +
                                                _vm._s(
                                                  _vm.getPromotionGoodsPrice(
                                                    promotion,
                                                    scope.row.prices
                                                  )
                                                )
                                            ),
                                            _c("br")
                                          ])
                                        : _vm._e()
                                    }
                                  )
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.isShowGoodsPromotion(
                            scope.row.promotionRuleCountrys,
                            "OLD"
                          )
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-weight": "bold",
                                        "text-align": "left",
                                        display: "block"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("common.RegularUserPromotion")
                                        )
                                      ),
                                      _c("br")
                                    ]
                                  ),
                                  _vm._l(
                                    scope.row.promotionRuleCountrys,
                                    function(promotion, index) {
                                      return promotion.userType == "OLD"
                                        ? _c("span", { key: index }, [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm.$dict.getDictValue(
                                                    "countrys",
                                                    promotion.countryCode
                                                  )
                                                ) +
                                                ":" +
                                                _vm._s(
                                                  _vm.getPromotionGoodsPrice(
                                                    promotion,
                                                    scope.row.prices
                                                  )
                                                )
                                            ),
                                            _c("br")
                                          ])
                                        : _vm._e()
                                    }
                                  )
                                ],
                                2
                              )
                            : _vm._e(),
                          !scope.row.promotionRuleCountrys ||
                          scope.row.promotionRuleCountrys.length == 0
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$i18n.t(
                                      "common.ThereAreCurrentlyNoProductPromotions"
                                    )
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2761734144
                )
              })
            : _vm._e(),
          !_vm.scene.isNonSelf && _vm.tableColumSetting.includes("price1")
            ? _c("el-table-column", {
                attrs: {
                  prop: "prices",
                  label: _vm.$t("common.price1"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return _vm._l(scope.row.prices, function(s, index) {
                          return _c("span", { key: index }, [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      s.salePriceEbp || s.salePriceEbp >= 0,
                                    expression:
                                      "s.salePriceEbp || s.salePriceEbp >= 0"
                                  }
                                ]
                              },
                              [
                                _vm._v(
                                  _vm._s(s.countryCode) +
                                    ":" +
                                    _vm._s(Number(s.salePriceEbp).toFixed(2)) +
                                    _vm._s(
                                      _vm.$dict.getDictCurrencyValue(
                                        s.countryCode
                                      )
                                    )
                                )
                              ]
                            ),
                            _c("br")
                          ])
                        })
                      }
                    }
                  ],
                  null,
                  false,
                  3674495581
                )
              })
            : _vm._e(),
          _vm.tableColumSetting.includes("state")
            ? _c("el-table-column", {
                attrs: {
                  prop: "shelf",
                  label: _vm.$t("common.state"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.shelf == true
                            ? _c(
                                "span",
                                { staticStyle: { color: "#46a8a0" } },
                                [_vm._v(_vm._s(_vm.$t("common.Listed")))]
                              )
                            : _c("span", [
                                _vm._v(_vm._s(_vm.$t("common.Notlisted")))
                              ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1434026108
                )
              })
            : _vm._e(),
          _vm.tableColumSetting.includes("inventory")
            ? _c("el-table-column", {
                attrs: {
                  prop: "prices",
                  label: _vm.$t("common.inventory"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return _vm._l(scope.row.goodsSpecials, function(
                          s,
                          index
                        ) {
                          return _c("span", { key: index }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(s.countryCode) +
                                ":" +
                                _vm._s(s.ecomRemainQty)
                            ),
                            _c("br")
                          ])
                        })
                      }
                    }
                  ],
                  null,
                  false,
                  1490175556
                )
              })
            : _vm._e(),
          _vm.tableColumSetting.includes("Inventorybottomline")
            ? _c("el-table-column", {
                attrs: {
                  prop: "goodsSpecials",
                  label: _vm.$t("common.Inventorybottomline"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return _vm._l(scope.row.goodsSpecials, function(
                          s,
                          index
                        ) {
                          return _c("span", { key: index }, [
                            _vm._v(
                              " " +
                                _vm._s(s.countryCode) +
                                ":" +
                                _vm._s(s.stockLimit)
                            ),
                            _c("br")
                          ])
                        })
                      }
                    }
                  ],
                  null,
                  false,
                  3546543463
                )
              })
            : _vm._e(),
          !_vm.scene.isNonSelf &&
          _vm.tableColumSetting.includes("AutomaticallysynchronizeEBP")
            ? _c("el-table-column", {
                attrs: {
                  prop: "ebpSync",
                  label: _vm.$t("common.AutomaticallysynchronizeEBP"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.ebpSync
                            ? _c("span", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      scope.row.ebpSync.isAuto == true
                                        ? _vm.$t("common.yes")
                                        : _vm.$t("common.no")
                                    ) +
                                    "\n                    "
                                )
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2919801599
                )
              })
            : _vm._e(),
          !_vm.scene.isNonSelf &&
          _vm.tableColumSetting.includes("ForcesynchronizationofEBP")
            ? _c("el-table-column", {
                attrs: {
                  prop: "ebpSync",
                  label: _vm.$t("common.ForcesynchronizationofEBP"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.ebpSync
                            ? _c("span", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      scope.row.ebpSync.isForce == true
                                        ? _vm.$t("common.yes")
                                        : _vm.$t("common.no")
                                    ) +
                                    "\n                    "
                                )
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  617649389
                )
              })
            : _vm._e(),
          !_vm.scene.isNonSelf &&
          _vm.tableColumSetting.includes("Noinventorydisplay")
            ? _c("el-table-column", {
                attrs: {
                  prop: "noStockShow",
                  label: _vm.$t("common.Noinventorydisplay"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return _vm._l(scope.row.goodsSpecials, function(
                          s,
                          index
                        ) {
                          return _c("span", { key: index }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(s.countryCode) +
                                ":" +
                                _vm._s(
                                  s.noStockShow
                                    ? _vm.$t("common.Display")
                                    : _vm.$t("common.DoNotDisplay")
                                )
                            ),
                            _c("br")
                          ])
                        })
                      }
                    }
                  ],
                  null,
                  false,
                  2705908761
                )
              })
            : _vm._e(),
          _vm.tableColumSetting.includes("VariantInformation")
            ? _c("el-table-column", {
                attrs: {
                  prop: "variantList",
                  label: _vm.$t("common.VariantInformation"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return _vm._l(scope.row.variantList, function(
                          s,
                          index
                        ) {
                          return _c("span", { key: index }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(s.parameterName) +
                                ":" +
                                _vm._s(s.parameterValue)
                            ),
                            _c("br")
                          ])
                        })
                      }
                    }
                  ],
                  null,
                  false,
                  1471514494
                )
              })
            : _vm._e(),
          _vm.tableColumSetting.includes("Creationtime")
            ? _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: _vm.$t("common.Creationtime"),
                  "render-header": _vm.renderRuHeader,
                  align: "center",
                  width: "150",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("formatDate")(scope.row.syTime))
                            ),
                            _c("br")
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  783659069
                )
              })
            : _vm._e(),
          _vm.tableColumSetting.includes("Updatetime")
            ? _c("el-table-column", {
                attrs: {
                  prop: "updateTime",
                  label: _vm.$t("common.Updatetime"),
                  "render-header": _vm.renderGeHeader,
                  align: "center",
                  width: "150",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("formatDate")(scope.row.updateTime)
                                )
                            ),
                            _c("br")
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3294629254
                )
              })
            : _vm._e(),
          _vm.tableColumSetting.includes("label")
            ? _c("el-table-column", {
                attrs: {
                  prop: "prices",
                  label: _vm.$t("common.label"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return _vm._l(scope.row.tags, function(s, index) {
                          return _c("span", { key: index }, [
                            _c(
                              "span",
                              [
                                _vm._v(_vm._s(s.countryCode) + ":"),
                                _c(
                                  "el-button",
                                  { attrs: { type: "text", size: "small" } },
                                  [_vm._v(_vm._s(s.tagCode))]
                                ),
                                _c("br")
                              ],
                              1
                            )
                          ])
                        })
                      }
                    }
                  ],
                  null,
                  false,
                  142818540
                )
              })
            : _vm._e(),
          !_vm.scene.isNonSelf && _vm.tableColumSetting.includes("PreSaleEvent")
            ? _c("el-table-column", {
                attrs: {
                  prop: "goodsPreSaleCountries",
                  label: _vm.$t("common.PreSaleEvent"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return _vm._l(scope.row.goodsPreSaleCountries, function(
                          s,
                          index
                        ) {
                          return _c("span", { key: index }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$dict.getDictValue(
                                    "countrys",
                                    s.countryCode
                                  )
                                )
                              ),
                              _c("br")
                            ])
                          ])
                        })
                      }
                    }
                  ],
                  null,
                  false,
                  1587668425
                )
              })
            : _vm._e(),
          !_vm.scene.isNonSelf && _vm.tableColumSetting.includes("Operator")
            ? _c("el-table-column", {
                attrs: {
                  prop: "updateByName",
                  label: _vm.$t("common.Operator"),
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              })
            : _vm._e(),
          !_vm.scene.isNonSelf &&
          _vm.tableColumSetting.includes("Evaluation_Shown_total")
            ? _c("el-table-column", {
                attrs: {
                  prop: "barCode",
                  label: _vm.$t("common.Evaluation_Shown_total"),
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.comment
                            ? _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function($event) {
                                      return _vm.toUrl(scope.row.barCode)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(scope.row.comment.adoptNum) +
                                      "/" +
                                      _vm._s(scope.row.comment.commentNum)
                                  ),
                                  _c("br")
                                ]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1911046160
                )
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("common.operation"),
              align: "center",
              fixed: "right",
              width: "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.$common.hasPermission("Update", _vm.module_code)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.edit(scope.row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("common.edit")))]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.goodsDetails(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("common.ProductDetails")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.editPrices(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("common.Fixedprice1")))]
                    ),
                    _vm.preSaleShow
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.editPreSaleCountry(scope.row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("common.PreSaleSwitch")))]
                        )
                      : _vm._e(),
                    _vm.preSaleShow
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.editPreSale(scope.row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("common.PresaleConfiguration"))
                            )
                          ]
                        )
                      : _vm._e(),
                    scope.row.shelf &&
                    _vm.$common.hasPermission("PutAway", _vm.module_code)
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "red" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.offorPut(scope.row, "down")
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("common.OffTheShelf")))]
                        )
                      : !scope.row.shelf &&
                        _vm.$common.hasPermission("SoldOut", _vm.module_code)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.offorPut(scope.row, "up")
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("common.OnTheShelf")))]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { display: "inline-block" } },
        [
          _c("el-pagination", {
            staticStyle: {
              display: "inline-block",
              height: "25px",
              "line-height": "25px"
            },
            attrs: {
              "current-page": _vm.listQuery.page,
              "page-size": _vm.listQuery.size,
              layout: "total, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          }),
          _c(
            "div",
            {
              staticStyle: {
                "margin-left": "20px",
                display: "inline-block",
                height: "37px",
                "line-height": "37px"
              }
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "12px", "font-weight": "500" } },
                [_vm._v(_vm._s(_vm.$t("common.PerPage")) + "：")]
              ),
              _c(
                "el-dropdown",
                {
                  on: { command: _vm.handleGoodsCommand },
                  scopedSlots: _vm._u([
                    {
                      key: "dropdown",
                      fn: function() {
                        return [
                          _c(
                            "el-dropdown-menu",
                            _vm._l(_vm.goodsPageSizeList, function(item) {
                              return _c(
                                "el-dropdown-item",
                                { key: item, attrs: { command: item } },
                                [_vm._v(_vm._s(item))]
                              )
                            }),
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "span",
                    { staticClass: "el-dropdown-link" },
                    [
                      _vm._v(
                        "\n                    " + _vm._s(_vm.goodsPageSize)
                      ),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right"
                      }),
                      _c(
                        "el-icon",
                        { staticClass: "el-icon--right" },
                        [_c("arrow-down")],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t(
              "common.Pleasecompletetheproductinformationbeforelistingit"
            ),
            visible: _vm.dialogtipsVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogtipsVisible = $event
            }
          }
        },
        [
          _vm.dialogtipsVisible
            ? _c(
                "div",
                [
                  _vm._l(_vm.tipsList, function(item, index) {
                    return _c("div", { key: index }, [
                      _vm._v(_vm._s(index + 1) + ":" + _vm._s(item))
                    ])
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "20%" },
                          on: {
                            click: function($event) {
                              _vm.dialogtipsVisible = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "20%" },
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              _vm.dialogtipsVisible = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.confirm1")))]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t(
              "common.PleaseCompleteTheProductInformationBeforeSettingUpThePresale"
            ),
            visible: _vm.dialogtipsPreSaleVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogtipsPreSaleVisible = $event
            }
          }
        },
        [
          _vm.dialogtipsPreSaleVisible
            ? _c(
                "div",
                [
                  _vm._l(_vm.tipsList, function(item, index) {
                    return _c("div", { key: index }, [
                      _vm._v(_vm._s(index + 1) + ":" + _vm._s(item))
                    ])
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "20%" },
                          on: {
                            click: function($event) {
                              _vm.dialogtipsPreSaleVisible = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "20%" },
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              _vm.dialogtipsPreSaleVisible = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.confirm1")))]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.tabsTitle,
            visible: _vm.dialogTabsVisible,
            "close-on-click-modal": false,
            "before-close": _vm.closeDialog
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogTabsVisible = $event
            }
          }
        },
        [
          _vm.dialogTabsVisible
            ? _c(
                "div",
                [
                  _c("tabs-dialog", {
                    attrs: {
                      formData: _vm.formData,
                      defaultFormData: _vm.defaultFormData,
                      dictionary: _vm.dictionary,
                      defaultDictionary: _vm.defaultDictionary,
                      formListQuery: _vm.formListQuery,
                      special: _vm.special,
                      treeData: _vm.treeData
                    },
                    on: {
                      closeDialog: _vm.closeDialog,
                      handleSearchList: _vm.handleSearchListF
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.goodsTitle,
            visible: _vm.dialogDetailVisible,
            "close-on-click-modal": false,
            "before-close": _vm.closeDetailsDialog
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogDetailVisible = $event
            }
          }
        },
        [
          _vm.dialogDetailVisible
            ? _c(
                "div",
                [
                  _c("goods-details-dialog", {
                    attrs: {
                      formData: _vm.formData,
                      languageList: _vm.languageList,
                      countryList: _vm.countryList
                    },
                    on: {
                      closeDetailsDialog: _vm.closeDetailsDialog,
                      handleSearchList: _vm.handleSearchListF
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("common.Batchsearch"),
            "close-on-click-modal": false,
            visible: _vm.dialogSearchVisible,
            width: "30%",
            "before-close": _vm.closeSearchDialog
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogSearchVisible = $event
            }
          }
        },
        [
          _vm.dialogSearchVisible
            ? _c(
                "div",
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("common.Enterproductbarcodeandsubmitforaddition")
                      ) + "："
                    )
                  ]),
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 4 },
                      placeholder: _vm.$t("common.Enterproductbarcode")
                    },
                    model: {
                      value: _vm.describe,
                      callback: function($$v) {
                        _vm.describe = $$v
                      },
                      expression: "describe"
                    }
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        { on: { click: _vm.closeSearchDialog } },
                        [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loadingSearch
                          },
                          on: { click: _vm.handleBatchSerach }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.confirm1")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("common.Editlistingcountry"),
            "close-on-click-modal": false,
            visible: _vm.dialogCountryVisible,
            width: "30%",
            "before-close": _vm.closeCountryDialog
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogCountryVisible = $event
            }
          }
        },
        [
          _vm.dialogCountryVisible
            ? _c(
                "div",
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { indeterminate: _vm.isIndeterminate },
                      on: { change: _vm.handleCheckAllChange },
                      model: {
                        value: _vm.checkAll,
                        callback: function($$v) {
                          _vm.checkAll = $$v
                        },
                        expression: "checkAll"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("common.SelectAll")) +
                          "\n                "
                      )
                    ]
                  ),
                  _c("div", { staticStyle: { margin: "15px 0" } }),
                  _c(
                    "el-checkbox-group",
                    {
                      on: { change: _vm.handleCheckedCitiesChange },
                      model: {
                        value: _vm.checkCountryList,
                        callback: function($$v) {
                          _vm.checkCountryList = $$v
                        },
                        expression: "checkCountryList"
                      }
                    },
                    _vm._l(_vm.dictionary.country, function(item, index) {
                      return _c(
                        "el-checkbox",
                        { key: item.code, attrs: { label: item.code } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.name) +
                              "\n                    "
                          )
                        ]
                      )
                    }),
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        { on: { click: _vm.closeCountryDialog } },
                        [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleCountryClick }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.confirm1")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("common.EditCategory"),
            "close-on-click-modal": false,
            visible: _vm.dialogClassVisible,
            width: "30%",
            "before-close": _vm.closeClassDialog
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogClassVisible = $event
            }
          }
        },
        [
          _vm.dialogClassVisible
            ? _c(
                "div",
                [
                  _c("el-tree", {
                    ref: "treeClass",
                    attrs: {
                      props: _vm.defaultProps,
                      data: _vm.dictionary.classifyTree,
                      "node-key": "goodsClassifyId",
                      "default-checked-keys": _vm.checkedKeys,
                      "show-checkbox": ""
                    }
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c("el-button", { on: { click: _vm.closeClassDialog } }, [
                        _vm._v(_vm._s(_vm.$t("common.cancel")))
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleGoodsClassify }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.confirm1")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("common.SynchronizeSKU"),
            "close-on-click-modal": false,
            visible: _vm.dialogSKUVisible,
            width: "30%",
            "before-close": _vm.closeSKUDialog
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogSKUVisible = $event
            }
          }
        },
        [
          _vm.dialogSKUVisible
            ? _c("div", [
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.$t("common.ConfirmsynchronizationofSKU")) + "?"
                  )
                ]),
                _c("div", { staticStyle: { "margin-top": "10px" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("common.SynchronizationrequiresdockingwithEBP")
                    )
                  )
                ]),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c("el-button", { on: { click: _vm.closeSKUDialog } }, [
                      _vm._v(_vm._s(_vm.$t("common.cancel")))
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.putGoodsSku }
                      },
                      [
                        _vm.restTime
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("common.PleaseWait")) +
                                  "(" +
                                  _vm._s(_vm.restTime) +
                                  ")"
                              )
                            ])
                          : !_vm.restTime
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.$t("common.confirm1")))
                            ])
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("common.SynchronizeSubBarcodes"),
            "close-on-click-modal": false,
            visible: _vm.dialogSubBarCodeVisible,
            width: "30%",
            "before-close": _vm.closeSubBarCodeDialog
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogSubBarCodeVisible = $event
            }
          }
        },
        [
          _vm.dialogSubBarCodeVisible
            ? _c("div", [
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.$t("common.ConfirmSynchronizationSubBarcodes"))
                  )
                ]),
                _c("div", { staticStyle: { "margin-top": "10px" } }, [
                  _vm._v(_vm._s(_vm.$t("common.SynchronizationEbpEanSUB")))
                ]),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      { on: { click: _vm.closeSubBarCodeDialog } },
                      [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          disabled: _vm.syncSubBarCodeRestTime
                        },
                        on: { click: _vm.syncSubBarCode }
                      },
                      [
                        _vm.syncSubBarCodeRestTime
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("common.PleaseWait")) +
                                  "(" +
                                  _vm._s(_vm.syncSubBarCodeRestTime) +
                                  ")"
                              )
                            ])
                          : !_vm.syncSubBarCodeRestTime
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.$t("common.confirm1")))
                            ])
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("common.Setlabel"),
            "close-on-click-modal": false,
            visible: _vm.dialogMarkisible,
            width: "30%",
            "before-close": _vm.closeMarkDialog
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogMarkisible = $event
            }
          }
        },
        [
          _vm.dialogMarkisible
            ? _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "mark",
                      attrs: {
                        model: _vm.markQuery,
                        rules: _vm.tagRules,
                        size: "small",
                        "label-width": "140px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("common.country"),
                            prop: "country"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-width",
                              attrs: {
                                multiple: "",
                                placeholder: _vm.$t("common.country")
                              },
                              model: {
                                value: _vm.markQuery.country,
                                callback: function($$v) {
                                  _vm.$set(_vm.markQuery, "country", $$v)
                                },
                                expression: "markQuery.country"
                              }
                            },
                            _vm._l(_vm.dictionary.country, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.code }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("common.label"),
                            prop: "tagCode"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-width",
                              attrs: {
                                placeholder: _vm.$t("common.PleaseSelectATag")
                              },
                              model: {
                                value: _vm.markQuery.tagCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.markQuery, "tagCode", $$v)
                                },
                                expression: "markQuery.tagCode"
                              }
                            },
                            _vm._l(_vm.goods_tag_code, function(item) {
                              return _c("el-option", {
                                key: item.code,
                                attrs: { label: item.name, value: item.code }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c("el-button", { on: { click: _vm.closeMarkDialog } }, [
                        _vm._v(_vm._s(_vm.$t("common.cancel")))
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.postGoodsTag("mark")
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.confirm1")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("common.UpdateRecords"),
            visible: _vm.dialogRecordVisible,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogRecordVisible = $event
            },
            close: _vm.closeRecordDialog
          }
        },
        [
          _vm.dialogRecordVisible
            ? _c(
                "div",
                [
                  _c("FormTableDialog", {
                    ref: "FormTableDialog",
                    attrs: {
                      argFrom: _vm.argFrom2,
                      dictionary: _vm.dictionary,
                      placeholderFrom: _vm.placeholderFrom,
                      closeFooter: true
                    },
                    on: {
                      handleSearchList: _vm.getGoodsImport,
                      handleResetSearch: _vm.getGoodsImport
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "tableSlot",
                          fn: function() {
                            return [
                              _c(
                                "el-table",
                                {
                                  ref: "tableData2",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "row-key": "goodsId",
                                    data: _vm.importData.list,
                                    border: "",
                                    "header-cell-style": {
                                      background: "#EAEEF9",
                                      color: "#32353a"
                                    }
                                  },
                                  on: {
                                    "selection-change":
                                      _vm.handleSelectionChange
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "goodsImportId",
                                      label: _vm.$t("common.number"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "fileName",
                                      label: _vm.$t("common.file"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "createTime",
                                      label: _vm.$t("common.Importtime"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm._f("formatDate")(
                                                      scope.row.createTime
                                                    )
                                                  ) +
                                                  "\n                                "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      129628226
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "status",
                                      label: _vm.$t("common.UpdateResults"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.status == 0
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "common.Updating"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                : scope.row.status == 1
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("common.success")
                                                      )
                                                    )
                                                  ])
                                                : scope.row.status == 2
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "common.Abortive"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3820700395
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "operator",
                                      label: _vm.$t("common.Operator"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: _vm.$t("common.operation"),
                                      align: "center",
                                      fixed: "right"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.status == 1
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "primary"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.downFileUrl(
                                                            scope.row.fileUrl
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "common.Downloadfiles"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      4260176216
                                    )
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "block" },
                                [
                                  _c("el-pagination", {
                                    attrs: {
                                      "current-page": _vm.importQuery.page,
                                      "page-size": _vm.importQuery.size,
                                      layout:
                                        "total, prev, pager, next, jumper",
                                      total: _vm.importData.total
                                    },
                                    on: {
                                      "current-change":
                                        _vm.handleCurrentChangeRecord
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      270457143
                    )
                  })
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.tagType,
            "close-on-click-modal": false,
            visible: _vm.dialogNEWVisible,
            width: "30%",
            "before-close": _vm.closeDialogNEW
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogNEWVisible = $event
            }
          }
        },
        [
          _vm.dialogNEWVisible
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center"
                    }
                  },
                  [
                    _vm.tagBeginTime || _vm.tagEndTime
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("formatDate")(_vm.tagBeginTime))
                            )
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("formatDate")(_vm.tagEndTime)))
                          ])
                        ])
                      : _c("div", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("common.DefaultRule")) +
                              "\n                    "
                          )
                        ])
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            _vm.dialogNEWVisible = false
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("common.confirm1")))]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("common.BottomInventory"),
            "close-on-click-modal": false,
            visible: _vm.dialogBaselineisible,
            width: "30%",
            "before-close": _vm.closeBaselineDialog
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogBaselineisible = $event
            }
          }
        },
        [
          _vm.dialogBaselineisible
            ? _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function($event) {
                          _vm.isStockLimit = !_vm.isStockLimit
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.edit")))]
                  ),
                  _c(
                    "el-table",
                    {
                      ref: "pagedata",
                      staticStyle: { width: "100%", "margin-top": "20px" },
                      attrs: { data: _vm.goodsSpecials, border: "" }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "countryCode",
                          label: _vm.$t("common.country"),
                          align: "center",
                          "show-overflow-tooltip": ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$dict.getDictValue(
                                            "countrys",
                                            scope.row.countryCode
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2253843042
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "stockLimit",
                          label: _vm.$t("common.Inventorybottomline"),
                          align: "center",
                          "show-overflow-tooltip": ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          scope.row.stockLimit >= 0 &&
                                          _vm.isStockLimit,
                                        expression:
                                          "scope.row.stockLimit >= 0 && isStockLimit"
                                      }
                                    ],
                                    attrs: {
                                      oninput: "value=value.replace(/\\D/g,'')",
                                      placeholder: _vm.$t(
                                        "common.Pleaseentercontent"
                                      )
                                    },
                                    model: {
                                      value: scope.row.stockLimit,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "stockLimit", $$v)
                                      },
                                      expression: "scope.row.stockLimit"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            scope.row.stockLimit >= 0 &&
                                            !_vm.isStockLimit,
                                          expression:
                                            "scope.row.stockLimit >= 0 && !isStockLimit"
                                        }
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(scope.row.stockLimit) + " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          434671257
                        )
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isStockLimit,
                          expression: "isStockLimit"
                        }
                      ],
                      staticStyle: { "margin-top": "20px" }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("common.Synchronizetoothertenants")) +
                          ":\n                    "
                      ),
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.curTenantList,
                            callback: function($$v) {
                              _vm.curTenantList = $$v
                            },
                            expression: "curTenantList"
                          }
                        },
                        _vm._l(_vm.filterNoTenantList, function(item) {
                          return _c(
                            "el-checkbox",
                            {
                              key: item.tenant_id,
                              attrs: { label: item.tenant_id }
                            },
                            [
                              _vm._v(
                                _vm._s(item.name) + "\n                        "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        { on: { click: _vm.closeBaselineDialog } },
                        [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.postGoodsBaseline }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.confirm1")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("common.SynchronizeEBPproductinformation"),
            "close-on-click-modal": false,
            visible: _vm.dialogEBPVisible,
            width: "30%",
            "before-close": _vm.closeDialogEBP
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogEBPVisible = $event
            }
          }
        },
        [
          _vm.dialogEBPVisible
            ? _c(
                "div",
                [
                  _c("h3", [
                    _vm._v(
                      _vm._s(_vm.$t("common.Afterturningonsynchronization")) +
                        "："
                    )
                  ]),
                  _c("div", { staticStyle: { margin: "10px 0px" } }, [
                    _vm._v(
                      "1." +
                        _vm._s(
                          _vm.$t("common.Synchronizeproducttitlesbylanguage")
                        ) +
                        "；"
                    )
                  ]),
                  _c("div", { staticStyle: { margin: "10px 0px" } }, [
                    _vm._v(
                      "2." +
                        _vm._s(
                          _vm.$t(
                            "common.SynchronisedAlertsForLargeProductImages"
                          )
                        ) +
                        "；"
                    )
                  ]),
                  _c("div", { staticStyle: { margin: "10px 0px" } }, [
                    _vm._v(
                      "3." +
                        _vm._s(
                          _vm.$t("common.SynchronisedAlertsForGraphicDetails")
                        ) +
                        "；"
                    )
                  ]),
                  _c(
                    "el-form",
                    { attrs: { model: _vm.formEBPQuery } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "common.Automaticallysynchronizeornot"
                            ),
                            prop: "isAuto"
                          }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.formEBPQuery.isAuto,
                                callback: function($$v) {
                                  _vm.$set(_vm.formEBPQuery, "isAuto", $$v)
                                },
                                expression: "formEBPQuery.isAuto"
                              }
                            },
                            _vm._l(_vm.isAuto, function(item) {
                              return _c(
                                "el-radio",
                                { key: item.id, attrs: { label: item.value } },
                                [_vm._v(_vm._s(item.name))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "common.Doyouwanttoforcesynchronization"
                            ),
                            prop: "isForce"
                          }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.formEBPQuery.isForce,
                                callback: function($$v) {
                                  _vm.$set(_vm.formEBPQuery, "isForce", $$v)
                                },
                                expression: "formEBPQuery.isForce"
                              }
                            },
                            _vm._l(_vm.isForce, function(item) {
                              return _c(
                                "el-radio",
                                { key: item.id, attrs: { label: item.value } },
                                [_vm._v(_vm._s(item.name))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("common.tenant"),
                            prop: "tenantIdList"
                          }
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.formEBPQuery.tenantIdList,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.formEBPQuery,
                                    "tenantIdList",
                                    $$v
                                  )
                                },
                                expression: "formEBPQuery.tenantIdList"
                              }
                            },
                            _vm._l(_vm.filterNoTenantList, function(
                              item,
                              index
                            ) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: index,
                                  attrs: { label: item.tenant_id }
                                },
                                [_vm._v(_vm._s(item.name))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c("el-button", { on: { click: _vm.closeDialogEBP } }, [
                        _vm._v(_vm._s(_vm.$t("common.cancel")))
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.EBPloading },
                          on: { click: _vm.postEBP }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.confirm1")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("common.SynchronizeThePackageQuantity"),
            "close-on-click-modal": false,
            visible: _vm.dialogPackageQtyVisible,
            width: "30%",
            "before-close": _vm.closeDialogPackageQty
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogPackageQtyVisible = $event
            }
          }
        },
        [
          _vm.dialogPackageQtyVisible
            ? _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "packageQtyForm",
                      attrs: {
                        model: _vm.formPackageQtyQuery,
                        rules: _vm.formPackageQtyRules
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("common.PackageQuantity"),
                            prop: "packageQty",
                            "label-width": "120px"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "common.PleaseEnterSeparatedBy"
                              )
                            },
                            model: {
                              value: _vm.formPackageQtyQuery.packageQty,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formPackageQtyQuery,
                                  "packageQty",
                                  $$v
                                )
                              },
                              expression: "formPackageQtyQuery.packageQty"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t(
                              "common.Synchronizetoothertenants"
                            ),
                            prop: "tenantIdList",
                            "label-width": "120px"
                          }
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.formPackageQtyQuery.tenantIdList,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.formPackageQtyQuery,
                                    "tenantIdList",
                                    $$v
                                  )
                                },
                                expression: "formPackageQtyQuery.tenantIdList"
                              }
                            },
                            _vm._l(_vm.filterNoTenantList, function(
                              item,
                              index
                            ) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: index,
                                  attrs: { label: item.tenant_id }
                                },
                                [_vm._v(_vm._s(item.name))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        { on: { click: _vm.closeDialogPackageQty } },
                        [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.packageQtyLoading
                          },
                          on: { click: _vm.postPackageQty }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.confirm1")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("common.Noinventorydisplay"),
            "close-on-click-modal": false,
            visible: _vm.dialogOutStockVisible,
            width: "30%",
            "before-close": _vm.closeDialogOutStock
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogOutStockVisible = $event
            }
          }
        },
        [
          _vm.dialogOutStockVisible
            ? _c(
                "div",
                [
                  _c(
                    "el-form",
                    { attrs: { model: _vm.formOutStockQuery } },
                    [
                      _vm._l(_vm.formOutStockQuery.noStockShowDtoList, function(
                        item,
                        index
                      ) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: item.countryLable } },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: item.noStockShow,
                                      callback: function($$v) {
                                        _vm.$set(item, "noStockShow", $$v)
                                      },
                                      expression: "item.noStockShow"
                                    }
                                  },
                                  _vm._l(_vm.outStock, function(item) {
                                    return _c(
                                      "el-radio",
                                      {
                                        key: item.id,
                                        attrs: { label: item.value }
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("common.Synchronizetoothertenants"),
                            prop: "tenantSyn"
                          }
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.formOutStockQuery.tenantList,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.formOutStockQuery,
                                    "tenantList",
                                    $$v
                                  )
                                },
                                expression: "formOutStockQuery.tenantList"
                              }
                            },
                            _vm._l(_vm.filterNoTenantList, function(
                              item,
                              index
                            ) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: index,
                                  attrs: { label: item.tenant_id }
                                },
                                [_vm._v(_vm._s(item.name))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    2
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        { on: { click: _vm.closeDialogOutStock } },
                        [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.outStockloading
                          },
                          on: { click: _vm.postOutStock }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.confirm1")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _vm.dialogPreSaleVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$i18n.t("common.AddPreSalePromotion"),
                visible: _vm.dialogPreSaleVisible,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogPreSaleVisible = $event
                }
              }
            },
            [
              _c("AddGoodsPreSaleConfig", {
                attrs: {
                  defaultDictionary: _vm.dictionary,
                  formItem: _vm.formItemPreSaleConfig,
                  defaultFormItem: _vm.defaultFormItemPreSaleConfig
                },
                on: { closePreSaleDialog: _vm.closePreSaleDialog }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.dialogPreSaleCountryVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$i18n.t("common.PreSaleSwitch"),
                visible: _vm.dialogPreSaleCountryVisible,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogPreSaleCountryVisible = $event
                }
              }
            },
            [
              _c("GoodsPresaleCountryDialog", {
                attrs: { dictionary: _vm.dictionary, rowData: _vm.rowData },
                on: { closePreSaleCountryDialog: _vm.closePreSaleCountryDialog }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("common.SetAFixedPrice"),
            visible: _vm.dialogPricesVisible,
            "close-on-click-modal": false,
            "before-close": _vm.closePricesDialog
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogPricesVisible = $event
            }
          }
        },
        [
          _vm.dialogPricesVisible
            ? _c(
                "div",
                [
                  _c("goods-prices-dialog", {
                    attrs: {
                      formData: _vm.formData,
                      defaultFormData: _vm.defaultFormData
                    },
                    on: {
                      handleSearchList: _vm.handleSearchListF,
                      closePricesDialog: _vm.closePricesDialog
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "500px",
            title: _vm.$t("common.ListDisplay"),
            visible: _vm.showColumnVisible,
            "close-on-click-modal": false,
            "before-close": _vm.closeColumnDialog
          },
          on: {
            "update:visible": function($event) {
              _vm.showColumnVisible = $event
            }
          }
        },
        [
          _vm.showColumnVisible
            ? _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      ref: "columnTable",
                      staticClass: "table-fixed",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableColumn,
                        border: "",
                        "row-key": "goodsTableColumnSettingId"
                      },
                      on: {
                        "selection-change": _vm.handleColumnSelectionChange
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          label: _vm.$t("common.Displayornot")
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("common.FieldName"),
                          prop: "columnValue"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "30px" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "100px" },
                          on: { click: _vm.closeColumnDialog }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "100px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.submitColumnChange }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.confirm1")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }